import React from 'react';
import Layout from '../components/layout';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { CenteredContentBlock, Grid, Column } from '../components/styleBook';

const ContactInformationPage = () => {
  return (
    <Layout>
      <section className="contact-information">
        <h1 id="yhteystiedot">Yhteystiedot</h1>

        <CenteredContentBlock>
          Olemme avoinna arkisin klo 9-17 ja viikonloppuisin erikseen sovittuina
          aikoina.
        </CenteredContentBlock>

        <Grid>
          <Column>
            <h2>Osoitteet</h2>
            <p>
              <strong>Käyntiosoite</strong>
              <br />
              Timeimage Oy
              <br />
              Hämeentie 157, 6. krs,
              <br />
              Loft Office,
              <br />
              00560 Helsinki
              <br />
            </p>

            <p>Y-Tunnus: 2008026-3</p>

            <p>
              <strong>Laskutusosoite</strong>
              <br />
              Timeimage Oy
              <br />
              Vaskihuhdantie 4 A 2,
              <br />
              00740 Helsinki
              <br />
            </p>
          </Column>

          <Column>
            <h2>Henkilöstö</h2>

            <p>
              <strong>Arto Kulju</strong>{' '}
              <OutboundLink href="http://fi.linkedin.com/pub/arto-kulju/50/579/a68">
                <img
                  title="Linkedin"
                  src="/images/linkedin.png"
                  alt="Linkedin Arto Kulju"
                  width="20"
                  height="15"
                />
              </OutboundLink>
              <br />
              toimitusjohtaja
              <br />
              Puh. 0500 434 040
            </p>

            <p>Sähköpostiosoitteet muotoa etunimi.sukunimi@timeimage.fi</p>

            <p>
              <strong>Ylläpidon päivystysnumero</strong>
              <br />
              044 504 3404
            </p>
          </Column>
        </Grid>
      </section>
    </Layout>
  );
};

export default ContactInformationPage;
